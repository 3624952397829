<template>
  <b-card
    no-body
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <b-card-body>

        <div class="trade-stock-div mb-1"><strong>Trade Stock 3LM</strong></div>
        <v-grid
          ref="refGridSopStock3LM"
          :source="source" 
          :columns="columns" 
          :columnTypes="pluginRevo"
          theme="material" 
          resize="true"
          autoSizeColumn = true
          class="grid-container-sop-stock"
        ></v-grid>

        <div class="mt-2 trade-stock-div mb-1"><strong>Trade Stock 6LM</strong></div>
        <v-grid
          ref="refGridSopStock6LM"
          :source="source2" 
          :columns="columns2" 
          :columnTypes="pluginRevo"
          theme="material" 
          resize="true"
          autoSizeColumn = true
          class="grid-container-sop-stock"
        ></v-grid>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody,
  BRow,
} from 'bootstrap-vue'
import { ref, getCurrentInstance, watch, toRefs } from 'vue'
import VGrid from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,

    CustomLoading,
    VGrid,
  },
  props: {
    filtersData: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    // props
    const { filtersData } = toRefs(props)

    const source = ref([])
    const columns = ref([])
    const source2 = ref([])
    const columns2 = ref([])
    const isLoading = ref(true)
    const refGridSopStock3LM = ref(null)
    const refGridSopStock6LM = ref(null)
    const pluginRevo = { 
      'numeric': new NumberColumnType('0,0'),
    }

    const vm = getCurrentInstance().proxy

    columns.value = [
      {
        "name": "Canal",
        "prop": "canal",
        "size": 120,
        "autoSize": true,
        "readonly": true,
      },
      {
        "name": "Enero",
        "prop": "Mes01_L3M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Febrero",
        "prop": "Mes02_L3M",
        "size": 90,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Marzo",
        "prop": "Mes03_L3M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Abril",
        "prop": "Mes04_L3M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Mayo",
        "prop": "Mes05_L3M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Junio",
        "prop": "Mes06_L3M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Julio",
        "prop": "Mes07_L3M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Agosto",
        "prop": "Mes08_L3M",
        "size": 90,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Septiembre",
        "prop": "Mes09_L3M",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Octubre",
        "prop": "Mes10_L3M",
        "size": 100,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Noviembre",
        "prop": "Mes11_L3M",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Diciembre",
        "prop": "Mes12_L3M",
        "size": 110,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      
    ]

    columns2.value = [
      {
        "name": "Canal",
        "prop": "canal",
        "size": 120,
        "autoSize": true,
        "readonly": true,
      },
      {
        "name": "Enero",
        "prop": "Mes01_L6M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Febrero",
        "prop": "Mes02_L6M",
        "size": 90,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Marzo",
        "prop": "Mes03_L6M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Abril",
        "prop": "Mes04_L6M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Mayo",
        "prop": "Mes05_L6M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Junio",
        "prop": "Mes06_L6M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Julio",
        "prop": "Mes07_L6M",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Agosto",
        "prop": "Mes08_L6M",
        "size": 90,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Septiembre",
        "prop": "Mes09_L6M",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Octubre",
        "prop": "Mes10_L6M",
        "size": 100,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Noviembre",
        "prop": "Mes11_L6M",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      {
        "name": "Diciembre",
        "prop": "Mes12_L6M",
        "size": 110,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
      },
      
    ]

    watch(filtersData, (newValue, oldValue) => {
      isLoading.value = true
      filtersData.value = newValue
    })

    // subscription
    vm.$apollo.addSmartSubscription('get_tradestock_l3ml6m', {
      query: gql`
        subscription get_tradestock_l3ml6m($customWhere: json = null) {
          function_get_tradestock_l3ml6m(order_by: {canal: desc}, args: {customWhere: $customWhere})  {    
            canal
            year
            Mes01_L3M
            Mes01_L6M
            Mes02_L3M
            Mes02_L6M
            Mes03_L3M
            Mes03_L6M
            Mes04_L3M
            Mes04_L6M
            Mes05_L3M
            Mes05_L6M
            Mes06_L3M
            Mes06_L6M
            Mes07_L3M
            Mes07_L6M
            Mes08_L3M
            Mes08_L6M
            Mes09_L3M
            Mes09_L6M
            Mes10_L3M
            Mes10_L6M
            Mes11_L3M
            Mes11_L6M
            Mes12_L3M
            Mes12_L6M
          }
        }
      `,
      variables(){
        return {
          customWhere: filtersData.value
        }
      },
      result ({data}) {
        isLoading.value = false
        source.value = data.function_get_tradestock_l3ml6m
        source2.value = data.function_get_tradestock_l3ml6m
      },
    })

    return {
      source,
      columns,
      source2,
      columns2,
      isLoading,
      refGridSopStock3LM,
      refGridSopStock6LM,
      pluginRevo,

    }
  },
}
</script>

<style lang="scss">
.trade-stock-div {
  font-size: 20px;
}

.grid-container-sop-stock{
  max-width: 1250px;
  width: 100%;
  height: 200px;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
