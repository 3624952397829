<template>
  <div>
    <sop-filters
      :is-filters-sidebar-active.sync="isFiltersSidebarActive"
      @filters-data="setFiltersData"
    />

    <b-row class="align-items-center sticky-top">
      <b-button
        @click="isFiltersSidebarActive = true"
        variant="primary"
        class="m-1"
      >
        Filters
      </b-button>
      <div v-if="filtersData.canal.length > 0" class="mr-1"><strong>CANAL: </strong> {{filtersData.canal[0]}}</div>
      <div v-if="filtersData.bpu.length > 0" class="mr-1"><strong>BPU: </strong> {{filtersData.bpu[0]}}</div>
      <div v-if="filtersData.brand_category.length > 0" class="mr-1"><strong>BRAND CATEGORY: </strong> {{filtersData.brand_category[0]}}</div>
      <div v-if="filtersData.application_form.length > 0" class="mr-1"><strong>APPLICATION FORM: </strong> {{filtersData.application_form[0]}}</div>
    </b-row>

    <b-tabs
      pills
    >
      <!-- Tab: Info -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Info</span>
        </template>

        <b-row class="match-height">
          <b-col cols="12">
            <sop-pivot-bpu
            :filters-data="filtersData"
            ></sop-pivot-bpu>
          </b-col>

          <b-col cols="12">
            <sop-pivot-bpu-variacion
            :filters-data="filtersData"
            ></sop-pivot-bpu-variacion>
          </b-col>

          <b-col cols="12">
            <!-- FC -->
            <sop-fc
              :filters-data="filtersData"
            ></sop-fc>
          </b-col>
        </b-row>
        
      </b-tab>

      <!-- Tab: Resultados -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="DatabaseIcon"
            size="18"
            class="mr-50"
          />
          <span class="d-none d-sm-inline">Resultados</span>
        </template>

        <sop-tpen
        :filters-data="filtersData"
        ></sop-tpen>
      </b-tab>

      <!-- Tab: SI/SO/Tradestock -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="DatabaseIcon"
            size="18"
            class="mr-50"
          />
          <span class="d-none d-sm-inline">SI / SO / Tradestock</span>
        </template>

        <sop-chart
          :filters-data="filtersData"
        ></sop-chart>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { 
  BRow, BCol,
  BButton, BTab, BTabs,
 } from 'bootstrap-vue'
import SopPivotBpu from './SopPivotBpu.vue'
import SopPivotBpuVariacion from './SopPivotBpuVariacion.vue'
import SopTpen from './SopTpen.vue'
import SopChart from './SopChart/SopChart.vue'
import SopFilters from  './SopFilters.vue'
import SopFc from './SopFc/SopFc.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab, 
    BTabs,

    SopPivotBpu,
    SopPivotBpuVariacion,
    SopChart,
    SopFilters,
    SopFc,
    SopTpen,
  },
  data() {
    return {
      isFiltersSidebarActive: false,
      filtersData: {
        bpu: [],
        brand_category: [],
        application_form: [],
        canal: [],
        moneda: []
      },
    }
  },
  methods: {
    setFiltersData(data) {
      this.filtersData = Object.assign({}, data)
    }
  },
}
</script>

<style>
.custom-sticky {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.tab-content > .tab-pane:not(.active),
.pill-content > .pill-pane:not(.active) {
  display: block !important;
  height: 0;
  overflow-y: hidden;
} 
</style>
