<template>
  <b-card
    no-body
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <b-card-body>
        <v-grid
          ref="refGridSopTpen"
          :source="source" 
          :columns="columns" 
          :columnTypes="pluginRevo"
          theme="material" 
          resize="true"
          autoSizeColumn = true
          class="grid-container-sop-tpen"
          @beforeeditstart="beforeEditStart"
          @focusout="commitCell"
        ></v-grid>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody,
  BRow,
} from 'bootstrap-vue'
import { ref, getCurrentInstance } from 'vue'
import VGrid from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,

    CustomLoading,
    VGrid,
  },
  setup(props) {
    const source = ref([])
    const isLoading = ref(true)
    const refGridSopTpen = ref(null)
    const columns = ref([])
    const revoRowIndex = ref(null)
    const revoColName = ref(null)
    const pluginRevo = { 
      'numeric': new NumberColumnType('0,0'),
      'percentage': new NumberColumnType('0,0[.]00 %'),
      'numeric_negative': new NumberColumnType('(0,0)'),
    }

    const vm = getCurrentInstance().proxy

    //methods
    const clearRowSelection = () => {
      revoRowIndex.value = null
      revoColName.value = null
    }

    const beforeEditStart = (event) => {
      revoRowIndex.value = event.detail.rowIndex
      revoColName.value = event.detail.prop
    }

    const commitCell = async (event) => {
      const originalData = await refGridSopTpen.value.$el.getSource()
      const viewData = await refGridSopTpen.value.$el.getVisibleSource()
      let oldValue = viewData[revoRowIndex.value][revoColName.value]
      let newValue = event.target.value
      // if(revoColName.value == 'ajuste_netsales' ) {
      //   newValue = parseInt(newValue)
      // }
      
      if (oldValue != newValue) {
        viewData[revoRowIndex.value][revoColName.value] = newValue
        refGridSopTpen.value.$el.source = [...originalData]
      }
      clearRowSelection()
    }

    const getStyleGrid = (prop, model, column) => {
      let customStyle = {
        "text-align": "right"
      }

      if (column.prop == "year"
        || column.prop == "bpu") {
        delete customStyle["text-align"]
      }


      if(model.bpu.toLowerCase() == "total") {
        customStyle["background"] = "#a6a6a6"
        customStyle["color"] = "white"
        return customStyle
      }

      if(column.prop == "sop_vs_lecp" 
        || column.prop == "sop_vs_py" 
        || column.prop == "fc_vs_py"
        || column.prop == "sop_ro_mso_vs_py"
      ) {
        customStyle["background"] = "#DAE3F3"
        return customStyle
      }

      return customStyle
    }

    columns.value = [
      {
        "name": "YEAR",
        "prop": "year",
        "size": 80,
        "autoSize": true,
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "BPU",
        "prop": "bpu",
        "size": 120,
        "autoSize": true,
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "SOP",
        "prop": "sop",
        "size": 100,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "LE|CP",
        "prop": "le_cp",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "SOP vs LE|CP",
        "prop": "sop_vs_lecp",
        "size": 130,
        "autoSize": true,
        "columnType": 'percentage',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "PY",
        "prop": "py",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "SOP vs PY",
        "prop": "sop_vs_py",
        "size": 120,
        "autoSize": true,
        "columnType": 'percentage',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "FC vs PY",
        "prop": "fc_vs_py",
        "size": 100,
        "autoSize": true,
        "columnType": 'percentage',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "R&O",
        "prop": "r_o",
        "size": 80,
        "columnType": 'numeric_negative',
        "autoSize": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "MSO",
        "prop": "mso",
        "size": 80,
        "columnType": 'numeric_negative',
        "autoSize": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "SOP + RO + MSO",
        "prop": "sop_ro_mso",
        "size": 160,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "SOP + RO + MSO vs PY",
        "prop": "sop_ro_mso_vs_py",
        "size": 200,
        "autoSize": true,
        "columnType": 'percentage',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      }
    ]

    // subscription
    vm.$apollo.addSmartSubscription('dataTpen', {
      query: gql`
        subscription dataTpen {
          TPEN_Totales(order_by: [{year: asc}, {bpu: asc}])  {    
            year
            bpu
            sop
            le_cp
            sop_vs_lecp
            py
            sop_vs_py
            fc_vs_py
            r_o
            mso
            sop_ro_mso
            sop_ro_mso_vs_py
          }
        }
      `,
      result ({data}) {
        isLoading.value = false
        source.value = data.TPEN_Totales
      },
    })

    return {
      source,
      columns,
      isLoading,
      refGridSopTpen,
      pluginRevo,

      beforeEditStart,
      commitCell,
    }
  },
}
</script>

<style lang="scss">
.grid-container-sop-tpen {
  max-width: 1335px;
  width: 100%;
  height: 450px;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
