<template>
  <b-modal
    id="fc-add-row-general-modal"
    ref="fcAddRowGeneralModal"
    centered
    no-close-on-backdrop
    title="Add Row"
    hide-footer
    hide-header-close
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        id="fc-add-row-general-modal-form"
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <!-- Clasificacion -->
        <validation-provider
          #default="validationContext"
          name="Clasificacion"
          rules="required"
        >
          <b-form-group
            label="Clasificacion"
            label-for="Clasificacion"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.clasificacion"
              :options="clasificacionOptions"
              :reduce="val => val.value"
              input-id="Clasificacion"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Canal -->
        <validation-provider
          #default="validationContext"
          name="Canal"
          rules="required"
        >
          <b-form-group
            label="Canal"
            label-for="Canal"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.canal"
              :options="canalOptions"
              :reduce="val => val.value"
              input-id="Canal"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- BPU -->
        <validation-provider
          #default="validationContext"
          name="BPU"
          rules="required"
        >
          <b-form-group
            label="BPU"
            label-for="bpu"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.bpu"
              :options="bpuOptions"
              :reduce="val => val.value"
              input-id="bpu"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Brand Category -->
        <validation-provider
          #default="validationContext"
          name="Brand Category"
          rules="required"
        >
          <b-form-group
            label="Brand Category"
            label-for="brand_category"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.brand_category"
              :options="brandCategoryOptions"
              :reduce="val => val.value"
              input-id="brand_category"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- APPLICATION FORM -->
        <validation-provider
          #default="validationContext"
          name="Application Form"
          rules="required"
        >
          <b-form-group
            label="Application Form"
            label-for="application_form"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.application_form"
              :options="applicationFormOptions"
              :reduce="val => val.value"
              input-id="application_form"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Description -->
        <validation-provider
          #default="validationContext"
          name="Descripcion"
          rules="required"
        >
          <b-form-group
            label="Descripcion"
            label-for="descripcion"
          >
            <b-form-input
              id="descripcion"
              v-model="newRowData.promo_spgr"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Year-->
        <validation-provider
          #default="validationContext"
          name="Year"
          rules="required"
        >
          <b-form-group
            label="Year"
            label-for="year"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.year"
              :options="yearOptions"
              input-id="year"
              multiple
              :reduce="val => val.value"
              :components="{Deselect}"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Meses-->
        <validation-provider
          #default="validationContext"
          name="Meses"
          rules="required"
        >
          <b-form-group
            label="Meses"
            label-for="meses"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.month"
              :options="monthsOptions"
              input-id="meses"
              :reduce="val => val.value"
              multiple
              :components="{Deselect}"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Ajuste Netsales -->
        <validation-provider
          #default="validationContext"
          name="Ajuste Netsales"
          rules="required|regex:^-?[0-9]\d*(.\d+)?$"
        >
          <b-form-group
            label="Ajuste Netsales"
            label-for="ajuste_netsales"
          >
            <b-form-input
              id="ajuste_netsales"
              v-model="newRowData.ajuste_netsales"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2 justify-content-center">
          <b-button
            id="fc-add-row-general-modal-submit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner
              v-if="isLoadingButton"
              small
            />
            Add
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, getCurrentInstance, inject } from 'vue'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
import axios from '@axios'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      Deselect: {
        render: createElement => createElement('span', 'x'),
      },
    }
  },
  setup() {
    const bpuOptions = ref([])
    const brandCategoryOptions = ref([])
    const applicationFormOptions = ref([])
    const canalOptions = ref([])
    const clasificacionOptions = ref([])
    const yearOptions = ref([])
    const isLoadingButton = ref(false)

    const filtersData = inject("filtersData")

    const vm = getCurrentInstance().proxy

    const monthsOptions = [
      {label: "Enero", value: 1},
      {label: "Febrero", value: 2},
      {label: "Marzo", value: 3},
      {label: "Abril", value: 4},
      {label: "Mayo", value: 5},
      {label: "Junio", value: 6},
      {label: "Julio", value: 7},
      {label: "Agosto", value: 8},
      {label: "Septiembre", value: 9},
      {label: "Octubre", value: 10},
      {label: "Noviembre", value: 11},
      {label: "Diciembre", value: 12},
    ]

    const blankFiltersData = {
      clasificacion: '',
      canal: '',
      bpu: '',
      brand_category: '',
      application_form: '',
      promo_spgr: '',
      year: [],
      month: [],
      ajuste_netsales: 0
    }

    const newRowData = ref(JSON.parse(JSON.stringify(blankFiltersData)))
    const resetRowData = () => {
      newRowData.value = JSON.parse(JSON.stringify(blankFiltersData))
    }

    const onSubmit = async () => {
      const btnModal = document.getElementById('fc-add-row-general-modal-submit')

      try {
        isLoadingButton.value = true
        
        btnModal.setAttribute('disabled', true)

        await axios
        .post(`${process.env.VUE_APP_BASE_URL}/new_multiple_rows`,{
          data: newRowData.value
        })

        vm.$swal({
          title: 'Datos Registrados!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          willClose: ()=> {
            resetForm()
            vm.$bvModal.hide('fc-add-row-general-modal')
          }
        })
      } catch(e) {
        vm.$swal({
          title: 'Error al registrar nuevas filas!',
          text: `Intente de nuevo`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        isLoadingButton.value = false
        btnModal.removeAttribute('disabled')
      }
    }

    const onCancel = () => {
      // resetRowData()
      resetForm()
      vm.$bvModal.hide('fc-add-row-general-modal')
    }

    const getCustomWhere = () => {
      let customWhere = {}

      if(filtersData.value?.bpu.length > 0) customWhere["bpu"] = { "_eq": filtersData.value?.bpu[0]}
      if(filtersData.value?.application_form.length > 0) customWhere["application_form"] = { "_eq": filtersData.value?.application_form[0]}
      if(filtersData.value?.brand_category.length > 0) customWhere["brand_category"] = { "_eq": filtersData.value?.brand_category[0]}

      return customWhere
    }

    const getCustomWhereCanal = () => {
      let customWhere = {}

      if(filtersData.value?.canal.length > 0) customWhere["canal"] = { "_eq": filtersData.value?.canal[0]}

      return customWhere
    }

    // subscription
    vm.$apollo.addSmartSubscription('getFiltersDataBpu', {
      query: gql`
        subscription getFiltersDataBpu($customWhere: db_main_curr_month_bool_exp) {
          bpuOptions: db_main_curr_month(distinct_on: bpu, where: $customWhere) {
            label: bpu
            value: bpu
          }
        }
      `,
      variables(){
        return {
          customWhere: getCustomWhere()
        }
      },
      result ({data}) {
        if(data.bpuOptions.length == 1) newRowData.value.bpu = data.bpuOptions[0].value
        bpuOptions.value = data.bpuOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataBrandCategory', {
      query: gql`
        subscription getFiltersDataBrandCategory($customWhere: db_main_curr_month_bool_exp)  {
          brandCategoryOptions: db_main_curr_month(distinct_on: brand_category, where: $customWhere) {
            label: brand_category
            value: brand_category
          }
        }
      `,
      variables(){
        return {
          customWhere: getCustomWhere()
        }
      },
      result ({data}) {
        if(data.brandCategoryOptions.length == 1) newRowData.value.brand_category = data.brandCategoryOptions[0].value
        brandCategoryOptions.value = data.brandCategoryOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataApplicationForm', {
      query: gql`
        subscription getFiltersDataApplicationForm($customWhere: db_main_curr_month_bool_exp)  {
          applicationFormOptions: db_main_curr_month(distinct_on: application_form, where: $customWhere) {
            label: application_form
            value: application_form
          }
        }
      `,
      variables(){
        return {
          customWhere: getCustomWhere()
        }
      },
      result ({data}) {
        if(data.applicationFormOptions.length == 1) newRowData.value.application_form = data.applicationFormOptions[0].value
        applicationFormOptions.value = data.applicationFormOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataCanal', {
      query: gql`
        subscription getFiltersDataCanal($customWhere: db_main_curr_month_bool_exp) {
          canalOptions: db_main_curr_month(distinct_on: canal, where: $customWhere) {
            label: canal
            value: canal
          }
        }
      `,
      variables(){
        return {
          customWhere: getCustomWhereCanal()
        }
      },
      result ({data}) {
        if(data.canalOptions.length == 1) newRowData.value.canal = data.canalOptions[0].value
        canalOptions.value = data.canalOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataClasificacion', {
      query: gql`
        subscription getFiltersDataClasificacion{
          clasificacionOptions: db_main_curr_month(distinct_on: clasificacion,  where: {clasificacion: {_neq: "REALES"}}) {
            label: clasificacion
            value: clasificacion
          }
        }
      `,
      result ({data}) {
        clasificacionOptions.value = data.clasificacionOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataYear', {
      query: gql`
        subscription getFiltersDataYear{
          yearOptions: db_main_curr_month(distinct_on: year,  where: {clasificacion: {_neq: "REALES"}}) {
            label: year
            value: year
          }
        }
      `,
      result ({data}) {
        yearOptions.value = data.yearOptions
      },
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRowData)

    return {
      newRowData,
      onSubmit,
      onCancel,

      bpuOptions,
      brandCategoryOptions,
      applicationFormOptions,
      canalOptions,
      clasificacionOptions,
      monthsOptions,
      yearOptions,
      isLoadingButton,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
